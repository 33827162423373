import { put, takeLatest } from 'redux-saga/effects';
import { OrderActionEnum } from 'src/store/reducers/order/types';
import { OrderActionCreators } from 'src/store/reducers/order/action-creators';
import instance from 'src/sagas/set';

function* fetchDuePayment({ orderId }: ReturnType<typeof OrderActionCreators.getDuePayment>) {
  try {
    const { data, status } = yield instance.get(`stripe/create_pl/${orderId}`);
    yield put(OrderActionCreators.resultChargePayment(data, status));
  } catch (error: any) {
    console.log('error :>> ', error);
  }
}
export function* actionWatcherDuePayment() {
  yield takeLatest(OrderActionEnum.REQUEST_DUE_PAYMENT, fetchDuePayment);
}
