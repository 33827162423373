import React, { FC } from 'react';
import { Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

const PaymentTooltip: FC = () => {
  return (
    <div className='order-payment__link__button__tooltip-container'>
      <Tooltip
        leaveTouchDelay={3000}
        enterTouchDelay={50}
        title={
          <React.Fragment>
            <div className='pay-info'>
              <div className='pay-info__item'>
                <b>Collect Get Open Amount:</b>
                <br />
                The <span>Open Amount Link</span> is automatically generated & emailed to guests 3
                days before the trip. It shows $0.00 once paid.
              </div>
              <div className='pay-info__item'>
                <b>To get paid early</b>, or send payment reminders Click the{' '}
                <span>@ Pay Due Now</span> (in Notifications).
              </div>
              <div className='pay-info__item'>
                <b>Open amount changed:</b> Click the trash icon, then click the{' '}
                <span>@ Pay Due Now</span> (in Notifications).
              </div>
              <div className='pay-info__item'>
                <b>Get a partial payment:</b>
                <br />
                Click the <span>+ icon in Payments</span>, enter the amount, and send. Guests get an
                email to pay. Money shows in Payments & goes to your Stripe.
              </div>
              <div className='pay-info__item'>
                Check the <span>Ask Guest For A $250 Hold</span>. Guests are emailed to put a card
                on hold for incidentals. It’s released 3 days after the trip, you can not charge
                after that.
              </div>
              <div className='pay-info__item'>
                <b>After trip charges:</b>
                <br />
                Click the pen icon, to charge guests for incidentals. You can charge 1 time, it is
                FINAL. Money goes directly to your Stripe.
              </div>
              <div className='pay-info__item'>
                <b>Bill guest after a trip:</b>
                <br />
                You can: Fix dates, articles, & rates, then use the <span>Get Addi. Paid</span>{' '}
                button to bill the guest after a trip. This payment is not applied to their hold
                amount and is billed separately.
              </div>
            </div>
          </React.Fragment>
        }
      >
        <HelpIcon style={{ fill: 'rgb(0, 127, 255)', fontSize: '24px' }} />
      </Tooltip>
    </div>
  );
};

export default PaymentTooltip;
