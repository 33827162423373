import { FC, useEffect, useState } from 'react';
import lodash from 'lodash';
import { useSnackbar } from 'notistack';
import { Button, Modal } from '@mui/material';
import NumberInput from 'src/components/Items/input/NumberInput';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import { useActions } from 'src/hooks/useActions';
import { compareNumbers, validateField } from 'src/common/utils/validation';
import { IPartialRefund, IPayOrderFetch } from 'src/models/IOrder';
import './styles.scss';

interface IProps {
  refund: IPayOrderFetch;
  open: boolean;
  handleClose: () => void;
}

const RefundModal: FC<IProps> = ({ refund, open, handleClose }) => {
  const { refundPaymentOrder } = useActions();
  const { enqueueSnackbar } = useSnackbar();
  const [pay, setPay] = useState<IPartialRefund>({} as IPartialRefund);
  const [errors, setErrors] = useState({ price: '', note: '' });
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setPay({
      amount: lodash.divide(refund.price, 100),
      note: ''
    } as IPartialRefund);
  }, [open]);

  const handleChange = (name: string) => (value: string) => {
    setPay({ ...pay, [name]: value });
  };

  const validateInputs = (): boolean => {
    const price = compareNumbers(pay.amount, lodash.divide(refund.price, 100));
    const note = validateField(pay.note);
    setErrors({ price: price, note: note });
    return [price, note].every(el => !el);
  };

  const handleAddPay = () => {
    setCheck(false);
    if (validateInputs()) {
      enqueueSnackbar('Make a refund...');
      refundPaymentOrder({
        id: refund.id,
        amount: lodash.multiply(pay.amount, 100),
        note: pay.note
      });
      handleClose();
      setErrors({ price: '', note: '' });
    } else setCheck(true);
  };

  useEffect(() => {
    const listener = (event: { code: string; preventDefault: () => void }) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleAddPay();
      }
    };
    document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, [pay]);

  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition>
      <div className='order-show__new-pay'>
        <div className='order-show__new-pay__title'>Refund</div>
        <NumberInput
          data={pay.amount}
          setData={handleChange('amount')}
          placeholder='Refund'
          unlimit
          check={check}
          error={errors.price}
        />
        <div className={`refund-alert ${errors.price ? 'refund-error' : 'refund-alert__edit'}`}>
          {errors.price ? errors.price : 'Edit amount as needed.'}
        </div>
        <SimpleInput
          data={pay.note}
          setData={handleChange('note')}
          placeholder='Add a reason for this refund.'
          rows={2}
          check={check}
          multiline
          variant='outlined'
          error={errors.note}
        />
        <div className='refund-alert refund-alert__final'>Careful, it’s final.</div>
        <div className={'col-2'}>
          <SecondTypeButton children='Cancel' onClick={handleClose} />
          <Button onClick={handleAddPay} children='Refund' />
        </div>
      </div>
    </Modal>
  );
};

export default RefundModal;
