import { put, takeLatest } from 'redux-saga/effects';
import { OrderActionEnum } from 'src/store/reducers/order/types';
import { OrderActionCreators } from 'src/store/reducers/order/action-creators';
import instance from '../../set';

function* fetchRefundPaymentOrder({
  data
}: ReturnType<typeof OrderActionCreators.refundPaymentOrder>) {
  try {
    const { status } = yield instance.post('/stripe/refund', {
      data
    });
    yield put(OrderActionCreators.resultRefundPaymentOrder(status));
  } catch (e) {
    console.log(e);
  }
}

export function* actionWatcherRefundPaymentOrder() {
  yield takeLatest(OrderActionEnum.REQUEST_REFUND_PAYMENT_ORDER, fetchRefundPaymentOrder);
}
