import React, { useState, useEffect, FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Box, Button, CircularProgress, LinearProgress, Tooltip, styled } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NotificationMenu from './NotificationsMenu';
import Table from 'src/components/Items/table';
import ActionForm from 'src/components/Items/ActionForm';
import SimpleSelect from 'src/components/Items/select/SimpleSelect';
import SelectBaseOnId from 'src/components/Items/select/SelectBaseOnId';
import Badge from 'src/components/Items/Badge';
import UpdateOrderModal from '../Modal/Update';
import useIsMobile from 'src/hooks/useIsMobile';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { dineroFormat } from 'src/common/utils/dataFormat';
import instance from 'src/sagas/set';
import { BadgeThemeOrderEnum, IOrderFetch } from 'src/models/IOrder';
import Icons from 'src/common/icons';
import { toolTipStyle } from 'src/theme';
import './styles.scss';

export interface IOrderFilters {
  page: number;
  take: number;
  sorted: 'newest' | 'next out' | 'next return' | 'today out' | 'deleted' | 'leads' | 'blocked';
  host_id?: number;
}

interface IProps {
  isNext?: string;
}

const initOrderFilters: IOrderFilters = {
  take: 100,
  page: 1,
  sorted: 'newest'
};

const nextOutOrderFilters: IOrderFilters = {
  take: 100,
  page: 1,
  sorted: 'next out'
};

const nextReturnOrderFilters: IOrderFilters = {
  take: 100,
  page: 1,
  sorted: 'next return'
};

const OrderTable: FC<IProps> = ({ isNext }) => {
  const isMobile = useIsMobile();
  const isSmallMobile = useMemo(() => window.outerWidth < 520, [window.outerWidth]);
  const { getOrders, getHosts, initNotification } = useActions();
  const { role } = useTypedSelector(state => state.auth);
  const {
    order: { orderList, order, total, result, isLoading },
    host: { hostList }
  } = useTypedSelector(state => state);

  const StyledDiv = styled(Box)`
    > * {
      &:first-child {
        margin-bottom: ${isMobile ? '2px' : '10px'};
      }
    }
  `;

  const [selectedOrder, setSelectedOrder] = useState({} as IOrderFetch);
  const [filter, setFilter] = useState(initOrderFilters);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const updatedFilter = filter;
    filter.host_id === -1 && delete updatedFilter.host_id;
    !Object.values(isLoading).find(el => el) && getOrders(updatedFilter);
  }, [filter, result, order]);

  useEffect(() => {
    hostList.sort(function (a, b) {
      const nameA = a.first_name.toLowerCase(),
        nameB = b.first_name.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  }, [hostList]);

  useEffect(() => {
    if (isNext === 'out') {
      setFilter(nextOutOrderFilters), getOrders(nextOutOrderFilters);
    } else if (isNext === 'return') {
      setFilter(nextReturnOrderFilters), getOrders(nextReturnOrderFilters);
    } else {
      setFilter(initOrderFilters), getOrders(initOrderFilters);
    }
    getHosts();
    return () => {
      initNotification();
    };
  }, [isNext]);

  useEffect(() => {
    if (isNext === 'out') setTabValue(1);
    else if (isNext === 'return') setTabValue(2);
    else setTabValue(0);
  }, [isNext]);

  const onPaginationChange = (page: number) => setFilter({ ...filter, page });
  const onTakeChange = (take: number) => setFilter({ ...filter, take });
  const onHostChange = (host_id: number) => setFilter({ ...filter, host_id });

  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = (order: IOrderFetch) => {
    setOpenEdit(true);
    setSelectedOrder(order);
  };
  const handleCloseEdit = () => setOpenEdit(false);

  const _renderBadges = (
    pdf_order: string,
    driver_license_id: number,
    status: string,
    isSmall?: boolean
  ) => {
    let text;
    let theme;

    if (pdf_order && driver_license_id) {
      text = 'all';
      theme = BadgeThemeOrderEnum['leads'];
    } else if (pdf_order) {
      text = 'contract';
      theme = BadgeThemeOrderEnum['rejected'];
    } else if (driver_license_id) {
      text = 'license';
      theme = BadgeThemeOrderEnum['rejected'];
    } else {
      text = 'missing';
      theme = BadgeThemeOrderEnum['deleted'];
    }

    return (
      <StyledDiv className={`${isSmall && 'small-badge-container'}`}>
        <Badge text={text} theme={theme} document isSmall={isSmall} />
        {pdf_order && status === 'paid' && driver_license_id ? (
          <Badge text='Ready' theme={BadgeThemeOrderEnum['ready']} isSmall={isSmall} />
        ) : (
          <Badge
            text={status}
            theme={BadgeThemeOrderEnum[status as keyof typeof BadgeThemeOrderEnum]}
            isSmall={isSmall}
          />
        )}
      </StyledDiv>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        Cell: ({
          row: {
            original: { id }
          }
        }: any) => (
          <Link to={`/order/${id}`} className='react-link'>
            {id}
          </Link>
        ),
        width: 25
      },
      {
        Header: 'Email',
        accessor: 'client_email',
        Cell: ({
          row: {
            original: {
              id,
              client_id,
              client_email,
              notification,
              status,
              client: { last_name, first_name }
            }
          }
        }: any) => {
          return (
            <>
              <div className='flex_CS' style={{ marginBottom: '10px' }}>
                <Link to={`/client/${client_id}`} className='react-link'>
                  {client_email}
                </Link>
                <Tooltip
                  placement='right'
                  componentsProps={{
                    tooltip: {
                      sx: { ...toolTipStyle }
                    }
                  }}
                  title={
                    <div className='order-notification__list'>
                      <NotificationMenu
                        orderId={id}
                        status={status}
                        orderNotifications={notification}
                      />
                    </div>
                  }
                >
                  <div className='order-notification'>
                    <Icons.ArrowDown />
                  </div>
                </Tooltip>
              </div>
              <div style={{ textTransform: 'capitalize' }}>{`${last_name} ${first_name}`}</div>
            </>
          );
        }
      },
      {
        Header: 'Docs/Status',
        accessor: 'documents/status',
        Cell: ({
          row: {
            original: {
              pdf_order,
              client: { driver_license_id },
              status
            }
          }
        }: any) => <>{_renderBadges(pdf_order, driver_license_id, status)}</>
      },
      {
        Header: 'Start-City',
        accessor: 'start_location',
        Cell: ({
          row: {
            original: { location, sub_location, delivery_address }
          }
        }) => (
          <div>
            <div style={{ marginBottom: '5px' }}>{location}</div>
            {sub_location && (
              <div style={{ marginBottom: '5px', color: '#151c97' }}>{sub_location}</div>
            )}
            {delivery_address && delivery_address !== '' && (
              <Tooltip title={delivery_address}>
                <div style={{ color: '#4b566b' }}>{`${delivery_address.slice(0, 20)}...`}</div>
              </Tooltip>
            )}
          </div>
        )
      },
      {
        Header: 'End-City',
        accessor: 'end_location',
        Cell: ({
          row: {
            original: { location, end_location, sub_location_end, return_at }
          }
        }) => (
          <div>
            <div style={{ marginBottom: '5px' }}>
              {end_location !== '' ? end_location : location}
            </div>
            {sub_location_end && (
              <div style={{ marginBottom: '5px', color: '#151c97' }}>{sub_location_end}</div>
            )}
            {return_at && return_at !== '' && (
              <Tooltip title={return_at}>
                <div style={{ color: '#4b566b' }}>{`${return_at.slice(0, 20)}...`}</div>
              </Tooltip>
            )}
          </div>
        )
      },
      {
        Header: 'Start',
        accessor: 'start_date',
        Cell: ({
          row: {
            original: { start_date, timeOffset }
          }
        }) => (
          <div>
            {`${DateTime.fromISO(start_date, { zone: 'UTC' })
              .plus({ minutes: timeOffset })
              .toFormat('MM/dd/yy t')}`}
          </div>
        )
      },
      {
        Header: 'End',
        accessor: 'end_date',
        Cell: ({
          row: {
            original: { end_date, timeOffset }
          }
        }) => (
          <div>
            {`${DateTime.fromISO(end_date, { zone: 'UTC' })
              .plus({ minutes: timeOffset })
              .toFormat('MM/dd/yy t')}`}
          </div>
        )
      },
      {
        Header: 'Host',
        accessor: 'van.host_id',
        Cell: ({
          row: {
            original: {
              van: { host_id }
            }
          }
        }) => <>{hostList.map(h => h.id === host_id && `${h.first_name} ${h.last_name}`)}</>
      },
      {
        Header: 'Partial paid',
        accessor: 'partial_paid',
        Cell: ({
          row: {
            original: { partial_paid, currency }
          }
        }) => <>{dineroFormat(partial_paid, currency)}</>
      },
      {
        Header: 'Total price',
        accessor: 'price',
        Cell: ({
          row: {
            original: { price, currency }
          }
        }) => <>{dineroFormat(price, currency)}</>
      },
      {
        Header: 'Booked',
        accessor: 'created_at',
        Cell: ({
          row: {
            original: { created_at, timeOffset }
          }
        }) => (
          <div>
            {`${DateTime.fromISO(created_at, { zone: 'UTC' })
              .plus({ minutes: timeOffset })
              .toFormat('MM/dd/yy t')}`}
          </div>
        )
      },
      {
        accessor: 'actions',
        Cell: ({ row: { original } }: any) => (
          <>
            <div className='flex_CC'>
              {original.pdf_order && (
                <Tooltip title='Open contract'>
                  <a href={original.pdf_order} target='_blank' className='icon_stroke'>
                    <Icons.AttachmetDoc />
                  </a>
                </Tooltip>
              )}
              <Tooltip title='Edit order'>
                <>
                  <ActionForm.IconSet
                    title='order'
                    classWrapper='action-wrapper'
                    onEdit={() => handleOpenEdit(original)}
                  />
                </>
              </Tooltip>
            </div>
          </>
        )
      }
    ],
    [hostList]
  );

  const mobileColumns = useMemo(
    () => [
      {
        accessor: 'id',
        Cell: ({
          row: {
            original: {
              id,
              pdf_order,
              client: { driver_license_id },
              status
            }
          }
        }: any) => (
          <div>
            <>{id}</>
            {_renderBadges(pdf_order, driver_license_id, status, true)}
          </div>
        ),
        width: 25
      },
      {
        accessor: 'start_location',
        Cell: ({
          row: {
            original: { location, start_date, timeOffset }
          }
        }: any) => (
          <div>
            <div style={{ marginBottom: '5px' }}>{location}</div>
            <div className='text-wrap'>
              {`${DateTime.fromISO(start_date, { zone: 'UTC' })
                .plus({ minutes: timeOffset })
                .toFormat('MM/dd/yy t')}`}
            </div>
          </div>
        )
      },
      {
        accessor: 'end_location',
        Cell: ({
          row: {
            original: { location, end_location, end_date, timeOffset }
          }
        }) => (
          <div>
            <div style={{ marginBottom: '5px' }}>
              {end_location !== '' ? end_location : location}
            </div>
            <div className='text-wrap'>
              {`${DateTime.fromISO(end_date, { zone: 'UTC' })
                .plus({ minutes: timeOffset })
                .toFormat('MM/dd/yy t')}`}
            </div>
          </div>
        )
      }
    ],
    [hostList]
  );

  const [csv, setCsv] = useState('');
  const [loadingCsv, setLoadingScv] = useState(false);

  const handleDownloadCsv = async () => {
    setLoadingScv(true);
    try {
      const { data } = await instance.get(`csv`);
      setCsv(data);
    } catch (error) {
      console.log('error :>> ', error);
    } finally {
      setLoadingScv(false);
    }
  };
  const filterData = ['newest', 'next out', 'today out', 'leads', 'deleted', 'blocked'];
  const filterChange = (event: React.SyntheticEvent, value: number) => {
    setTabValue(value);
    setFilter({
      ...filter,
      page: 1,
      sorted: event.currentTarget.textContent as any
    });
  };

  const filterChangeInput = (value: string) => {
    const index = filterData.indexOf(value);
    setTabValue(index);
    setFilter({
      ...filter,
      page: 1,
      sorted: value as any
    });
  };

  const _renderFilterButton = () => filterData.map((i, index) => <Tab label={i} key={index} />);
  if (isLoading.all && !orderList.length) return <LinearProgress />;
  else
    return (
      <>
        <div className='order-table__filter'>
          {isNext ? (
            <SelectBaseOnId
              dataId={filter.host_id!}
              setDataId={(hostId: number) => onHostChange(hostId)}
              propsShow={['first_name', 'last_name']}
              dataArray={[{ first_name: 'All', id: -1 }, ...hostList]}
              variant={'outlined'}
              placeholder={'Filter by host'}
              style={{ width: '200px' }}
            />
          ) : (
            <>
              <Tabs
                selectionFollowsFocus
                onChange={filterChange}
                value={tabValue}
                indicatorColor='secondary'
              >
                {_renderFilterButton()}
              </Tabs>
              <div className='action-block'>
                <div className='action-block__input-container'>
                  {isMobile && (
                    <SimpleSelect
                      data={filterData[tabValue]}
                      dataArray={filterData}
                      setData={filterChangeInput}
                      variant={'outlined'}
                      style={{ marginBottom: `${role === 'admin' && isSmallMobile && '5px'}` }}
                    />
                  )}
                  {role === 'admin' && (
                    <div className='action-block__host-search'>
                      <SelectBaseOnId
                        dataId={filter.host_id!}
                        setDataId={(hostId: number) => onHostChange(hostId)}
                        propsShow={['first_name', 'last_name']}
                        dataArray={[{ first_name: 'All', id: -1 }, ...hostList]}
                        variant={'outlined'}
                        placeholder={'Filter by host'}
                        style={{ width: '200px' }}
                      />
                    </div>
                  )}
                </div>
                {csv ? (
                  <Button variant='contained'>
                    <a href={csv}>Download csv</a>
                  </Button>
                ) : (
                  <Button variant='contained' onClick={handleDownloadCsv} disabled={loadingCsv}>
                    Create csv
                    {loadingCsv && <CircularProgress size={15} />}
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
        {orderList.length ? (
          <div className='order-table'>
            <Table.Base
              data={orderList}
              columns={isMobile ? mobileColumns : columns}
              class='react-table'
              total={total!}
              filters={filter}
              onPaginationChange={onPaginationChange}
              onTakeChange={onTakeChange}
            />
            <UpdateOrderModal
              order={selectedOrder}
              open={openEdit}
              handleClose={handleCloseEdit}
              table
              fields={['note']}
            />
          </div>
        ) : null}
      </>
    );
};

export default OrderTable;
