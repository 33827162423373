import { requestState, responseState } from '../utils';
import {
  INotification,
  OrderAction,
  OrderActionEnum,
  OrderNotificationAction,
  OrderNotificationActionEnum,
  OrderState
} from './types';

const initNotification = {
  result: {},
  isLoading: {
    email: false,
    sms: false,
    sendSms: false
  }
};

const initAfterTripCharges = {
  result: {},
  isLoading: {
    get: false
  }
};

const initialState: OrderState = {
  orderList: [],
  isLoading: {
    all: false,
    get: false,
    add: false,
    delete: false,
    update: false,
    payment: false,
    action: false,
    refund: false,
    charge: false,
    transfer: false,
    doc: false
  },
  result: {},
  error: {},
  notification: { ...initNotification },
  afterTripCharges: { ...initAfterTripCharges },
  action: {}
};

const order = (state = initialState, action: OrderAction | OrderNotificationAction): OrderState => {
  switch (action.type) {
    case OrderActionEnum.REQUEST_ORDERS:
      return { ...state, isLoading: { ...state.isLoading, all: true } };
    case OrderActionEnum.RESULT_ORDERS:
      return {
        ...state,
        orderList: action.orderList,
        total: action.count,
        isLoading: { ...state.isLoading, all: !action.orderList }
      };
    case OrderActionEnum.REQUEST_ORDER:
      return {
        ...state,
        isLoading: { ...state.isLoading, get: true }
      };
    case OrderActionEnum.RESULT_ORDER:
      return {
        ...state,
        order: action.data,
        isLoading: { ...state.isLoading, get: !action.data }
      };
    case OrderActionEnum.ERROR_ORDER:
      return {
        ...state,
        isLoading: { ...state.isLoading, get: false },
        error: { ...state.error, get: action.status }
      };
    case OrderActionEnum.CLEAR_ORDER:
      return {
        ...state,
        order: undefined,
        result: {}
      };

    case OrderActionEnum.REQUEST_DELETE_ORDER:
      return requestState(state, 'delete');

    case OrderActionEnum.RESULT_DELETE_ORDER:
      return responseState(state, 'delete', action);

    case OrderActionEnum.REQUEST_UPDATE_ORDER:
      return requestState(state, 'update');

    case OrderActionEnum.REQUEST_ADD_ORDER:
      return requestState(state, 'add');

    case OrderActionEnum.RESULT_ADD_ORDER:
      return responseState(state, 'add', action);

    case OrderActionEnum.REQUEST_UPDATE_EXTRA_ORDER:
      return requestState(state, 'update');

    case OrderActionEnum.REQUEST_UPDATE_PRICE_ORDER:
      return requestState(state, 'update');

    case OrderActionEnum.RESULT_UPDATE_ORDER:
      return responseState(state, 'update', action);

    case OrderActionEnum.REQUEST_ADD_PAYMENT_ORDER:
      return requestState(state, 'payment');

    case OrderActionEnum.RESULT_ADD_PAYMENT_ORDER:
      return {
        ...state,
        isLoading: { ...state.isLoading, payment: false },
        result: {
          ...state.result,
          payment: action.status === 200 ? 'Payment added' : action.status
        }
      };

    case OrderActionEnum.REQUEST_ADD_PARTIAL_PAYMENT_ORDER:
      return requestState(state, 'payment');

    case OrderActionEnum.RESULT_ADD_PARTIAL_PAYMENT_ORDER:
      return {
        ...state,
        isLoading: { ...state.isLoading, payment: false },
        result: {
          ...state.result,
          payment: action.status === 200 ? 'Partial payment added' : action.status
        }
      };

    case OrderActionEnum.REQUEST_REFUND_PAYMENT_ORDER:
      return requestState(state, 'refund');

    case OrderActionEnum.RESULT_REFUND_PAYMENT_ORDER:
      return {
        ...state,
        isLoading: { ...state.isLoading, refund: false },
        result: { ...state.result, refund: action.status }
      };
    case OrderActionEnum.REQUEST_AFTERTRIP_CHARGES:
      return {
        ...state,
        afterTripCharges: {
          isLoading: { ...state.afterTripCharges.isLoading, get: true },
          result: {}
        }
      };
    case OrderActionEnum.RESULT_AFTERTRIP_CHARGES:
      return {
        ...state,
        afterTripCharges: {
          isLoading: { ...state.afterTripCharges.isLoading, get: false },
          result: { get: action.data }
        }
      };

    case OrderActionEnum.REQUEST_TRANSFER_PAYMENT:
      return {
        ...state,
        isLoading: { ...state.isLoading, transfer: true },
        result: { ...state.result, transfer: {} }
      };

    case OrderActionEnum.RESULT_TRANSFER_PAYMENT:
      return {
        ...state,
        isLoading: { ...state.isLoading, transfer: false },
        result: {
          ...state.result,
          transfer: { data: action.data, status: action.status }
        }
      };

    case OrderActionEnum.REQUEST_UPDATE_PAYMENT_ORDER:
      return requestState(state, 'payment');

    case OrderActionEnum.RESULT_UPDATE_PAYMENT_ORDER:
      return {
        ...state,
        isLoading: { ...state.isLoading, payment: false },
        result: { ...state.result, payment: action.data }
      };

    case OrderActionEnum.REQUEST_DELETE_PAYMENT_ORDER:
      return requestState(state, 'payment');

    case OrderActionEnum.RESULT_DELETE_PAYMENT_ORDER:
      return {
        ...state,
        isLoading: { ...state.isLoading, payment: false },
        result: {
          ...state.result,
          payment: action.status === 200 ? 'Payment deleted' : action.status
        }
      };

    case OrderActionEnum.REQUEST_ACTION_ORDER:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          action: true
        }
      };

    case OrderActionEnum.RESULT_ACTION_ORDER:
      return {
        ...state,
        isLoading: { ...state.isLoading, action: false },
        result: { ...state.result, action: action.status },
        action: { data: action.data }
      };

    case OrderNotificationActionEnum.REQUEST_EMAIL:
      return {
        ...state,
        notification: {
          isLoading: {
            ...state.notification.isLoading,
            email: true
          },
          result: {
            email: {} as INotification
          }
        }
      };
    case OrderNotificationActionEnum.RESULT_EMAIL:
      return {
        ...state,
        notification: {
          isLoading: {
            ...state.notification.isLoading,
            email: !action.status
          },
          result: {
            email: {
              orderId: action.orderId,
              status: action.status,
              type: action.typeNotification
            }
          }
        }
      };
    case OrderNotificationActionEnum.REQUEST_SMS:
      return {
        ...state,
        notification: {
          isLoading: {
            ...state.notification.isLoading,
            sms: true
          },
          result: {}
        }
      };
    case OrderNotificationActionEnum.RESULT_SMS:
      return {
        ...state,
        notification: {
          isLoading: {
            ...state.notification.isLoading,
            sms: !action.status
          },
          result: {
            sms: action.status
          }
        }
      };
    case OrderNotificationActionEnum.REQUEST_COPY_SMS:
      return {
        ...state,
        notification: {
          isLoading: {
            ...state.notification.isLoading,
            sendSms: true
          },
          result: {}
        }
      };
    case OrderNotificationActionEnum.RESULT_COPY_SMS:
      return {
        ...state,
        notification: {
          isLoading: {
            ...state.notification.isLoading,
            sendSms: !action.status
          },
          result: {
            smsText: {
              data: action.data,
              status: action.status
            }
          }
        }
      };
    case OrderActionEnum.REQUEST_AFTER_CHARGE_PAYMENT:
      return {
        ...state,
        isLoading: { ...state.isLoading, charge: true },
        result: { ...state.result, charge: {} }
      };

    case OrderActionEnum.REQUEST_DUE_PAYMENT:
      return {
        ...state,
        isLoading: { ...state.isLoading, charge: true },
        result: { ...state.result, charge: {} }
      };
    case OrderActionEnum.RESULT_CHARGE_PAYMENT:
      return {
        ...state,
        isLoading: { ...state.isLoading, charge: false },
        result: {
          ...state.result,
          charge: { data: action.data, status: action.status }
        }
      };
    case OrderNotificationActionEnum.INIT_NOTIFICATION:
      return {
        ...state,
        notification: { ...initNotification }
      };

    case OrderActionEnum.REQUEST_SHOW_CONTRACT:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          doc: true
        }
      };

    case OrderActionEnum.RESULT_SHOW_CONTRACT:
      return {
        ...state,
        isLoading: { ...state.isLoading, doc: false },
        result: { ...state.result, doc: action.data }
      };

    case OrderActionEnum.REQUEST_SHOW_LICENSE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          doc: true
        }
      };

    case OrderActionEnum.RESULT_SHOW_LICENSE:
      return {
        ...state,
        isLoading: { ...state.isLoading, doc: false },
        result: { ...state.result, doc: action.data }
      };

    case OrderActionEnum.REQUEST_SHOW_INSURANCE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          doc: true
        }
      };

    case OrderActionEnum.RESULT_SHOW_INSURANCE:
      return {
        ...state,
        isLoading: { ...state.isLoading, doc: false },
        result: { ...state.result, doc: action.data }
      };

    case OrderActionEnum.REQUEST_CHANGE_DATES:
      return {
        ...state,
        isLoading: { ...state.isLoading, update: true },
        result: {}
      };

    case OrderActionEnum.RESULT_CHANGE_DATES:
      return {
        ...state,
        order: action.data,
        isLoading: { ...state.isLoading, update: false },
        result: { ...state.result, update: action.status }
      };

    default:
      return state;
  }
};
export default order;
