import { Currency } from 'dinero.js';
import { IClient, IClientFetch } from './IClient';
import { IDriverFetch } from './IDriver';
import { IHostFetch } from './IHost';
import { IVanFetch } from './IVan';
import { IOnewayFetch } from './IOneway';

export interface IExtra {
  readonly id: number;
  readonly logo?: string;
  name: string;
  title: string;
  description: string;
  price: number;
  currency: Currency;
}

export interface IPartialRefund {
  readonly id?: number;
  amount: number;
  note: string;
}

export interface ICharge {
  readonly id?: number;
  title: string;
  amount: number;
}

export enum BadgeThemeOrderEnum {
  paid = 'green',
  leads = 'blue',
  rejected = 'orange',
  partial = 'grey',
  deleted = 'red',
  gotit = 'babyblue',
  blocked = 'pink',
  ready = 'dark-green'
}
export interface IPayOrder {
  readonly order_id: number;
  price: number;
  currency: Currency;
  note: string;
  isDelete: boolean;
  type: string;
  createBy: string;
}
export interface IPayOrderTypeName {
  readonly order_id: number;
  price: number;
  currency: Currency;
  note: string;
  isDelete: boolean;
  paymentType: string;
  createBy: string;
}

export interface IPartialPayOrderTypeName {
  price: number;
  currency: Currency;
  note: string;
  order_id: number;
}

export interface IPayOrderFetch extends IPayOrder {
  readonly id: number;
  stripe_id: string;
  transfer_from: number;
  transfer_to: number;
  created_at?: Date;
  updated_at?: Date;
}

export type ManualType = 'van' | 'extra';
export interface IManualItemOrderFetch {
  readonly id: number;
  readonly order_id: number;
  price: number;
  currency: Currency;
  type: ManualType;
  van_id?: number | null;
  extra_id?: number | null;
  created_at?: Date;
  updated_at?: Date;
}
export interface IOrderFetch {
  readonly id: number;
  readonly partial_paid: number;
  readonly client_id: number;
  readonly client_email: string;
  readonly van_id: number;
  readonly stripe_pi_id: string;
  location: string;
  sub_location?: string;
  sub_location_end?: string;
  price: number;
  currency: Currency;
  status: keyof typeof BadgeThemeOrderEnum;
  start_date: string;
  end_date: string;
  comment: string;
  voucher: string;
  client: IClientFetch;
  van: IVanFetch;
  host: IHostFetch;
  pdf_order: string;
  extras?: IExtra[];
  note: string;
  amount_seats?: number;
  delivery_address?: string;
  readonly created_at: string;
  readonly updated_at: string;
  manual_items: IManualItemOrderFetch[];
  handle_payments: IPayOrderFetch[];
  notification: string[];
  end_location?: string;
  isOneway: boolean;
  oneway_details: IOnewayFetch;
  update_history: any[];
  isDelete: boolean;
  additional_driver: IDriverFetch[] | [];
  payment_link: string;
  timeOffset: number;
  old_price?: number;
  fee_list: IExtra[];
  return_at?: string;
  stripe_refund_id?: string;
  stripe_charge_id?: string;
  stripe_hold_id?: string;
  after_trip_fee: ICharge[];
  prev_host: number[];
}

export interface ICalendarFilter {
  start_location?: string[];
  end_location?: string[];
  host_tag?: string[];
  region_tag?: string[];
  order_status?: string;
  all?: boolean;
}

export interface IOrderChangeDates {
  period: {
    start_date: string;
    end_date: string;
  };
  feeList: IExtra[];
}
