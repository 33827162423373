import { put, takeLatest } from 'redux-saga/effects';
import { OrderActionEnum } from 'src/store/reducers/order/types';
import { OrderActionCreators } from 'src/store/reducers/order/action-creators';
import instance from 'src/sagas/set';

function* fetchGetAfterTripCharges() {
  try {
    const { data } = yield instance.get('/afterCharge');
    yield put(OrderActionCreators.resultGetAfterTripCharges(data));
  } catch (error) {
    console.log('error :>> ', error);
  }
}
export function* actionWatcherGetAfterTripCharges() {
  yield takeLatest(OrderActionEnum.REQUEST_AFTERTRIP_CHARGES, fetchGetAfterTripCharges);
}
