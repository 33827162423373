import { FC, useEffect, useState } from 'react';
import lodash from 'lodash';
import { DateTime } from 'luxon';
import { Currency } from 'dinero.js';
import { useSnackbar } from 'notistack';
import { useClipboard } from 'use-clipboard-copy';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DeleteModalForm from 'src/components/Items/DeleteModalForm';
import NewPay from './NewPay';
// import SmsModal from '../../Notification/Modal/Sms';
import BookDateOrderModal from '../../Modal/BookDate';
import ArticlesModal from '../CartData/Modal';
import UpdateOrderModal from '../../Modal/Update';
import ChargeModal from './ChargedModal';
import RefundModal from './RefundModal';
import PaymentTooltip from './Tooltip';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import {
  dineroFormat,
  dineroSubtract,
  dineroSubtractAmount,
  fromISOtoFormat,
  fromJSDateToISO
} from 'src/common/utils/dataFormat';
import { IOrderFetch, IPayOrderFetch } from 'src/models/IOrder';
import Icons from 'src/common/icons';
import './styles.scss';

interface IProps {
  order: IOrderFetch;
}

const typesColors = {
  down_payment: 'green',
  due_payment: 'green',
  manual_payment: 'green',
  old_payment: 'grey',
  refund_payment: 'red',
  afterCharge_payment: 'green',
  pending_payment: 'grey',
  host_transfer: 'orange'
};

const Payments: FC<IProps> = ({ order }) => {
  const {
    id,
    price,
    currency,
    partial_paid,
    handle_payments,
    payment_link,
    start_date,
    end_date,
    prev_host,
    host,
    status
  } = order;

  const { sentEmail, getDuePayment, actionOrder, afterChargePayment, transferPayment, getOrder } =
    useActions();
  const clipboard = useClipboard();
  const { enqueueSnackbar } = useSnackbar();
  const { role } = useTypedSelector(state => state.auth);
  const hostId = useTypedSelector(state => state.auth.id);
  const hostList = useTypedSelector(state => state.host.hostList);
  // const isLoadingAction = useTypedSelector(state => state.order.isLoading.action);
  const isLoadingPayment = useTypedSelector(state => state.order.isLoading.payment);
  const isLoadingEmail = useTypedSelector(state => state.order.notification.isLoading.email);
  const isLoadingCharge = useTypedSelector(state => state.order.isLoading.charge);
  const isLoadingTransfer = useTypedSelector(state => state.order.isLoading.transfer);
  const resultTransfer = useTypedSelector(state => state.order.result.transfer);
  const resultCharge = useTypedSelector(state => state.order.result.charge);
  const due = dineroSubtractAmount(price, partial_paid!, currency);
  const daysToTripStart = DateTime.fromISO(start_date).diffNow('days').days;

  const [open, setOpen] = useState(false);
  const [openPayTextModal, setOpenPayTextModal] = useState(false);
  const [payText, setPayText] = useState('');
  const [refund, setRefund] = useState({} as IPayOrderFetch);
  const [openRefund, setOpenRefund] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteLink, setOpenDeleteLink] = useState(false);
  // const [openCharge, setOpenCharge] = useState(false);
  const [openCommon, setOpenCommon] = useState(false);
  const [openArticles, setOpenArticles] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({} as IOrderFetch);
  const [selectedPay, setSelectedPay] = useState<number | null>(null);
  const [isStripeId, setIsStripeId] = useState(false);

  const sortedPayments = lodash.sortBy(handle_payments, ['id'], ['desc']);
  const lastTransfer = lodash.findLast(handle_payments, el => el.type === 'host_transfer');

  useEffect(() => {
    const host = hostList.find(host => host.id === hostId);
    host && host.stripe_connect_id && setIsStripeId(true);
  }, [hostList, hostId]);

  useEffect(() => {
    if (resultCharge?.data) {
      setPayText(resultCharge.data), setOpenPayTextModal(true);
    }
  }, [resultCharge]);

  useEffect(() => {
    if (resultTransfer) {
      if (resultTransfer.status === 200) {
        enqueueSnackbar(resultTransfer.data, {
          variant: 'success'
        });
      } else if (resultTransfer.data) {
        enqueueSnackbar(resultTransfer.data, {
          variant: 'error'
        });
      }
    }
  }, [resultTransfer]);

  const handleOpenEdit = (order: IOrderFetch) => {
    setOpenEdit(true);
    setSelectedOrder(order);
  };

  const handleDelete = (id: number) => (e: any) => {
    e.stopPropagation();
    setSelectedPay(id);
    setOpenDelete(true);
  };

  // function changeCheckbox(actionType: string) {
  //   actionOrder(order.id, actionType);
  // }

  const handlePay = () => {
    status === 'partial' && getDuePayment(id);
    status === 'paid' &&
      end_date < DateTime.now().toISO() &&
      afterChargePayment(
        id,
        dineroSubtractAmount(price, partial_paid, currency),
        'After trip charge'
      );
    status === 'paid' && end_date > DateTime.now().toISO() && getDuePayment(id);
  };

  const handleRefund = (payment: IPayOrderFetch) => {
    setRefund(payment);
    setOpenRefund(true);
  };

  const handleCloseCharge = () => {
    resultCharge?.data && getOrder(id);
    setOpenPayTextModal(false);
  };

  const isShowRefund = (payment: IPayOrderFetch) => {
    if (
      role === 'admin' &&
      payment.stripe_id &&
      payment.type !== 'refund_payment' &&
      payment.note !== 'sprinter-rentals fee'
    )
      return true;
    else return false;
  };

  return (
    <>
      <div>
        <div className='order-show__title'>Payments</div>
        <div className='order-payment__history'>
          {sortedPayments.map(payment => (
            <div
              className={`order-payment__history__item ${
                payment.createBy === 'system'
                  ? payment.note === 'hold amount pending'
                    ? 'yellow'
                    : typesColors[payment.type as keyof typeof typesColors]
                  : payment.createBy === 'admin'
                  ? 'admin'
                  : 'host'
              }`}
              key={payment.id}
            >
              <div className='flex_CSB'>
                <div
                  className={`order-payment__history__item__note ${
                    payment.createBy !== 'system' && 'black-text'
                  }`}
                  title={payment.note}
                >
                  <div className='order-payment__history__item__note__container'>
                    <span>
                      {payment.note.indexOf('pi_') > 0
                        ? payment.note.split('pi_')[0]
                        : payment.note}
                    </span>
                    {payment.type !== 'pending_payment' && (
                      <div className='order-payment__history__item__note__stripe'>
                        <a
                          href={
                            payment.type === 'host_transfer'
                              ? `https://dashboard.stripe.com/connect/transfers/${payment.stripe_id}`
                              : `https://dashboard.stripe.com/payments/${payment.stripe_id}`
                          }
                          target='_blank'
                        >
                          stripe
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div className='order-payment__history__item__actions'>
                  {(payment.type === 'due_payment' ||
                    payment.type === 'down_payment' ||
                    payment.type === 'afterCharge_payment' ||
                    (payment.type === 'manual_payment' && isStripeId)) && (
                    <Tooltip title='Send receipt'>
                      <Button
                        className='order-payment__history__item__actions__receipt'
                        onClick={() =>
                          sentEmail(order.id, 'get receipt', undefined, payment.stripe_id)
                        }
                      >
                        {isLoadingEmail ? <CircularProgress size={15} /> : '@ receipt'}
                      </Button>
                    </Tooltip>
                  )}
                  {payment.isDelete &&
                    (isLoadingPayment ? (
                      <CircularProgress size={15} />
                    ) : (
                      <div onClick={handleDelete(payment.id)} className='icon_stroke'>
                        <Icons.Delete />
                      </div>
                    ))}
                  {isShowRefund(payment) && (
                    <Tooltip title='Refund'>
                      <div className='icon_stroke'>
                        <CurrencyExchangeIcon
                          onClick={() => handleRefund(payment)}
                          strokeWidth={0}
                          sx={{ fill: '#4b566b' }}
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className='flex_CSB'>
                <div className='order-payment__history__item__date'>
                  {fromISOtoFormat(fromJSDateToISO(payment.created_at), 'ff')}
                </div>
                <div className='order-payment__history__item__price'>
                  {dineroFormat(payment.price, payment.currency as Currency)}
                </div>
              </div>
            </div>
          ))}
        </div>
        <Button className='order-payment__add' onClick={() => setOpen(true)}>
          <AddCircleOutlineIcon />
          <span>Add or split payment</span>
        </Button>
        <div className='order-payment__total'>
          <div className='order-payment__total__item'>
            <div className='order-payment__total__title'>Total incl Fees:</div>
            <div className='order-payment__total__price paid'>{dineroFormat(price, currency)}</div>
          </div>
          <div className='order-payment__total__item'>
            <div className='order-payment__total__title'>Paid:</div>
            <div className='order-payment__total__price partial'>
              {dineroFormat(partial_paid, currency)}
            </div>
          </div>
          {dineroSubtractAmount(price, partial_paid!, currency) ? (
            <div className='order-payment__total__item'>
              <div className='order-payment__total__title'>
                Due:
                <div className='order-payment__total__subtitle'>
                  autom. charged 2 day before trip
                </div>
              </div>
              <div className='order-payment__total__price full'>
                {dineroSubtract(price, partial_paid, currency)}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {(role === 'admin' || role === 'super_host' || isStripeId) && (
          <div className='order-payment__extras'>
            <div className='order-payment__extras__title'>
              You Can Edit Order:
              <PaymentTooltip />
            </div>
            <div className='order-payment__extras__subtitle'>
              <div>
                Fix date/time&nbsp;
                <span onClick={() => setOpenCommon(true)}>Here</span>
              </div>
              <div>
                Add fees, tolls, fuel, in&nbsp;
                <span onClick={() => setOpenArticles(true)}>Articles</span>
              </div>
            </div>
            <div className='order-payment__extras__buttons'>
              {/* <Button onClick={() => setOpenCharge(true)} disabled={!stripe_hold_id}>
                {isLoadingPayment ? (
                  <CircularProgress size={15} />
                ) : (
                  <div>
                    <h3>Use Card on File</h3>
                    <p>limit $250</p>
                  </div>
                )}
              </Button> */}
              <Button
                onClick={handlePay}
                disabled={due <= 0 || daysToTripStart > 2 || isLoadingCharge}
              >
                {isLoadingCharge ? (
                  <CircularProgress size={15} />
                ) : (
                  <div>
                    <h3>Charge & email receipt</h3>
                    <p>Don’t overdue: Charge-backs</p>
                  </div>
                )}
              </Button>
            </div>
          </div>
        )}

        {/* <div className='order-payment__hold'>
          <div
            className={`order-notification__action__item 
              ${order.stripe_hold_id && 'disabled'}`}
          >
            {isLoadingAction ? (
              <CircularProgress size={15} />
            ) : (
              <Tooltip title='Sent email'>
                <CustomCheckbox
                  onChange={e => changeCheckbox(e.target.name)}
                  checked={order.stripe_hold_id ? true : false}
                  disabled={order.stripe_hold_id ? true : false}
                  name={'hold amount'}
                />
              </Tooltip>
            )}
          </div>
          <div className='order-payment__hold__container'>
            <h4>
              <b>Get Card On Hold</b> (optional)
            </h4>
            <p>
              We ask for a $250 hold, for after trip charges. Check box to remind guests, if checked
              card is in.
            </p>
          </div>
        </div> */}
      </div>
      <div>
        {role === 'admin' && (
          <Button
            className='order-payment__transfer'
            onClick={() => transferPayment(id)}
            disabled={!prev_host.length || (!!lastTransfer && lastTransfer.transfer_to === host.id)}
          >
            {isLoadingTransfer ? <CircularProgress size={15} /> : 'Transfer to current host'}
          </Button>
        )}
      </div>
      <UpdateOrderModal
        order={selectedOrder}
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        fields={['payment_link']}
      />
      <DeleteModalForm
        id={selectedPay!}
        type='payment'
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
      />
      <DeleteModalForm
        id={order.id}
        type='payment link'
        open={openDeleteLink}
        handleClose={() => setOpenDeleteLink(false)}
      />
      {/* <AfterTripChargesModal
        fees={after_trip_fee}
        currency={currency}
        open={openCharge}
        handleClose={() => setOpenCharge(false)}
        order_id={id}
      /> */}
      <NewPay
        orderId={order.id}
        isStripeId={isStripeId}
        handleClose={() => setOpen(false)}
        open={open}
      />
      {/* <NewPay
        orderId={order.id}
        isStripeId={false}
        isOpenCharge
        handleClose={() => setOpenAddPaid(false)}
        open={openAddPaid}
      /> */}
      <RefundModal refund={refund} handleClose={() => setOpenRefund(false)} open={openRefund} />
      {resultCharge && (
        <ChargeModal open={openPayTextModal} handleClose={handleCloseCharge} text={payText} />
      )}
      <BookDateOrderModal handleClose={() => setOpenCommon(false)} open={openCommon} />
      <ArticlesModal open={openArticles} handleClose={() => setOpenArticles(false)} order={order} />
    </>
  );
};

export default Payments;
